import {
  AppBar,
  Box,
  Container,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../components/Logo";
import HeroImages from "./HeroImages";
import MenuNavBar from "../components/MenuNavBar";

const styles = {
  toolbar: {
    display: { sm: "flex", xs: "flex" },
    justifyContent: { sm: "space-between", xs: "space-between" },
  },

  menunav: {
    display: { sm: "none", xs: "block" },
    textAlign: "end",
  },

  wrapAddress: {
    width: 540,
    display: { sm: "flex", xs: "none" },
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  typo: {
    fontSize: { sm: "1em", xs: 12 },
    fontWeight: 700,
    color: "secondary.contrastText",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    pr: 3,
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "0%",
      height: "2px",
      background: "rgb(245, 181, 5)",
      bottom: "0",
      transition: "width 0.6s ease", // Adjust the animation duration here
    },
    "&:hover::before": {
      width: "80%",
    },
  },

  appbar: {
    backgroundColor: "#0a4a49",
    backdropFilter: "blur(10px)",
    p: 1,
  },

  typohotline: {
    alignSelf: "flex-end",
    fontWeight: 900,
    fontSize: "0.8em",
    color: "#fff",
  },
};

function MainHeader() {
  return (
    <>
      <AppBar position="static" sx={styles.appbar}>
        <Container maxWidth="xl">
          <Toolbar sx={styles.toolbar}>
            <Box sx={styles.menunav}>
              <MenuNavBar />
            </Box>

            <Logo />

            <Box sx={styles.menunav}>
              <Link underline="none" href="tel: 02749996677">
                <Typography sx={styles.typohotline}>HOTLINE</Typography>
                <Typography sx={styles.typohotline}>0274.999.66.77</Typography>
              </Link>
            </Box>

            <Box name="wrap-address" sx={styles.wrapAddress}>
              {/* <InfoContact /> */}

              <RouterLink to="/" style={styles.typo}>
                <Typography sx={styles.typo}>Trang Chủ</Typography>
              </RouterLink>

              <RouterLink to="/he-thong-cua-hang" style={styles.typo}>
                <Typography sx={styles.typo}>Cửa Hàng</Typography>
              </RouterLink>

              <Typography sx={styles.typo}>
                <Link
                  sx={styles.typo}
                  underline="none"
                  href="tel: 0274.999.66.77"
                >
                  Hotline: 0274.999.66.77
                </Link>
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <HeroImages />
    </>
  );
}

export default MainHeader;
