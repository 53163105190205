import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

function SelectField({
  name,
  value,
  label,
  onChange,
  selectOption,
  renderValue,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <FormControl variant="outlined">
              <InputLabel>{label}</InputLabel>

              <Select
                error={error ? true : false}
                size="small"
                name={name}
                value={value || ""}
                label={label}
                onChange={onChange}
                color="success"
                renderValue={renderValue}
                {...other}
                {...field}
              >
                {selectOption.map((e, index) => (
                  <MenuItem value={e?.value} key={index}>
                    {e?.label}
                  </MenuItem>
                ))}
                <MenuItem value="">Khác</MenuItem>
              </Select>
              <FormHelperText error>{error?.message}</FormHelperText>
            </FormControl>
          </>
        )}
      />
    </>
  );
}

export default SelectField;
