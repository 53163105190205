import { Box, FormHelperText, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

import { cloudinaryUpload } from "../../utils/cloudinary";
import { FUploadImage } from "../../components/form";

function EditImageForm({ image, setValue }) {
  const [images, setImages] = useState(image || []);

  const [fileImg, setFileImg] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleDrop = useCallback(
    (acceptedFile) => {
      setError();
      const file = acceptedFile[0];

      if (file) {
        setFileImg(file);
        setValue(
          "image",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleSelectImg = async () => {
    if (!fileImg) return;
    try {
      setLoading(true);
      const imgPath = await cloudinaryUpload(fileImg);
      if (imgPath) {
        setImages((preImg) => [...preImg, imgPath]);
        setFileImg();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      setError(error);
    }
  };

  useEffect(() => {
    if (images?.length > image?.length) {
      setValue("image", images);
      setImages(images);
    } else {
      setImages(image);
      setValue("image", image);
    }
  }, [image, setImages, images, setValue]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          mb: 3,
          justifyContent: "space-evenly",
        }}
      >
        {images?.map((e, index) => (
          <img src={e} width="60px" height="60px" alt="hinh anh" key={index} />
        ))}
      </Box>

      <Box sx={{ height: "100%" }}>
        <FUploadImage
          name="image"
          accept="image/*"
          maxSize={3145728}
          onDrop={handleDrop}
        />
      </Box>
      {error && (
        <FormHelperText error sx={{ px: 2 }}>
          {error?.message}
        </FormHelperText>
      )}
      <Stack direction="row" justifyContent="center" mt={2} mb={1}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={handleSelectImg}
          color="success"
          sx={{ width: 150 }}
        >
          Chọn Hình
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default EditImageForm;
