import { Box, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  policy: {
    width: { md: 300, xs: "100%", sm: "100%" },
    pl: { md: 1, lg: 0, sm: 1, xs: 1 },
    height: 100,
    mt: { md: 0, xs: 1, sm: 1 },
    mb: { md: 0, xs: 1, sm: 1 },

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  typo: {
    fontSize: { sm: 13, xs: 12 },
    fontWeight: 900,
    color: "rgb(99,110,114)",
    textDecoration: "none",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "0%",
      height: "2px",
      background: "rgb(19, 128, 28)",
      bottom: "0",
      transition: "width 0.8s ease", // Adjust the animation duration here
    },
    "&:hover::before": {
      width: { sm: "45%", xs: "35%" },
    },
  },
};

function Policy() {
  const handleTypographyClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Box name="policy" sx={styles.policy}>
      <Typography variant="subtitle1">Chính Sách</Typography>

      <Typography
        to={"/chinh-sach-bao-mat"}
        component={RouterLink}
        variant="subtitle2"
        sx={styles.typo}
        onClick={handleTypographyClick}
      >
        Chính sách bảo mật
      </Typography>

      <Typography
        component={RouterLink}
        to={"/chinh-sach-bao-hanh"}
        sx={styles.typo}
        variant="subtitle2"
        onClick={handleTypographyClick}
      >
        Chính sách bảo hành
      </Typography>
    </Box>
  );
}

export default Policy;
