import { Stack, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Typography textAlign="center" mb={2}>
        404! Route Not Found
      </Typography>
      <Button onClick={() => navigate("/")} variant="outlined" color="success">
        Trở lại trang chủ
      </Button>
    </Stack>
  );
}

export default NotFoundPage;
