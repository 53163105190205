import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography, Alert } from "@mui/material";

const styles = {
  box: {
    textAlign: "center",
    gap: 2,
  },
  boxWrap: {
    minHeight: 280,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  typo: {
    fontSize: 18,
    fontWeight: 500,
    color: "primary.contrastText",
    m: 1,
  },
};

function FormAfterCreate({ user }) {
  return (
    <Box sx={styles.box}>
      <CheckCircleOutlineIcon color="success" fontSize="large" />
      <Typography sx={styles.typo}>Thành Công!</Typography>

      <Box sx={styles.boxWrap}>
        <Alert severity="success" color="success">
          Tên: {user.name}
        </Alert>

        <Alert severity="success" color="success">
          Số điện thoại: {user.phoneNumber}
        </Alert>
        <Alert severity="success" color="success">
          Sản phẩm quan tâm: {user.product}
        </Alert>
      </Box>

      <Typography sx={styles.typo}>
        Chúng tôi sẽ liên hệ với bạn sớm nhất!
      </Typography>
    </Box>
  );
}

export default FormAfterCreate;
