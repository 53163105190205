import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box, Link } from "@mui/material";
import LoadingScreen from "../../components/LoadingScreen";
import { getInfoCustomer } from "../../features/user/userSlice";
import { fDateTime } from "../../utils/formatTime";

const styles = {
  box: { width: { xs: "100%", md: 750 } },
  initialState: {
    pagination: {
      paginationModel: { page: 0, pageSize: 10 },
    },
  },
  boxCover: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
};

export default function ProductManagePage() {
  const dispatch = useDispatch();

  const { totalUser, isLoading } = useSelector((state) => state.users);

  const rows = [];
  totalUser?.forEach((e) => {
    rows.push({
      id: e._id,
      name: e.name,
      product: e.product,
      phoneNumber: e.phoneNumber,
      createdAt: fDateTime(e.createdAt),
    });
  });

  const columns = [
    { field: "name", headerName: "Tên", width: 150 },
    {
      field: "phoneNumber",
      headerName: "Số điện thoại",
      renderCell: (params) => (
        <Link
          color="primary.contrastText"
          underline="hover"
          href={`tel: ${params?.row?.phoneNumber}`}
        >
          {params?.row?.phoneNumber}
        </Link>
      ),
      width: 200,
    },
    { field: "product", headerName: "Sản phẩm quan tâm", width: 180 },
    {
      field: "createdAt",
      headerName: "Ngày Tháng Năm",
      width: 220,
    },
  ];

  useEffect(() => {
    dispatch(getInfoCustomer());

    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={styles.boxCover}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box sx={styles.box}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={styles.initialState}
            pageSizeOptions={[5, 10]}
          />
        </Box>
      )}
    </Box>
  );
}
