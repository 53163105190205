import { Box, Divider, keyframes } from "@mui/material";
import React, { useEffect } from "react";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const styles = {
  boxWrap: {
    marginTop: 2,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
    animation: `${fadeIn} 1.5s ease-out`,
    flexWrap: "wrap",
    overFlow: "hidden",
  },

  typo: {
    width: "100%",
  },

  // New style for underline on hover
  underlineOnHover: {
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "red",
      cursor: "pointer",
      transition: "all 500ms ease",
    },
    marginBottom: 1,
  },
};

function DescriptionProduct({ description }) {
  useEffect(() => {
    let renderDescription = document.getElementsByClassName("description");

    renderDescription[0].innerHTML = `${description}`;
  }, [description]);

  return (
    <>
      <Box sx={styles.boxWrap}>
        <Divider />
        <Box sx={{ lineHeight: 1.8, fontSize: 15 }}>
          <div style={{ textAlign: "justify" }} className="description"></div>
        </Box>
      </Box>
    </>
  );
}

export default DescriptionProduct;
