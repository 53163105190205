export const DOMAIN_URL_1 = process.env.REACT_APP_API_DOMAIN_1; //cyclic
export const DOMAIN_URL_2 = process.env.REACT_APP_API_DOMAIN_2; //vercel

export const PRODUCT_PER_PAGE = 9;

export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

export const PRODUCT_CATEGORY = [
  { value: "ip7", category: "iPhone 7" },
  { value: "ip8", category: "iPhone 8 - iPhone 8 Plus" },
  { value: "ipX", category: "iPhone X - iPhone Xs - iPhone Xs Max" },
  { value: "ip11", category: "iPhone 11- iPhone 11 Pro - iPhone 11 Pro Max" },
  { value: "ip12", category: "iPhone 12 - iPhone 12 Pro - iPhone 12 Pro Max" },
  { value: "ip13", category: "iPhone 13 - iPhone 13 Pro - iPhone 13 Pro Max" },
  { value: "ip14", category: "iPhone 14 - iPhone 14 Pro - iPhone 14 Pro Max" },
  { value: "ip15", category: "iPhone 15 - iPhone 15 Pro - iPhone 15 Pro Max" },
];

export const PRODUCT_IN_FORM = [
  { value: "iphone 7", label: "iPhone7" },
  { value: "iphone 8", label: "iPhone8 - 8Plus" },
  { value: "iphone X", label: "iPhone X" },
  { value: "iphone Xs", label: "iPhone Xs" },
  { value: "iphone Xs Max", label: "iPhone Xs Max" },
  { value: "iphone 11", label: "iPhone 11" },
  { value: "iphone 11 Pro", label: "iPhone 11 Pro" },
  { value: "iphone 11 Pro Max", label: "iPhone 11 Pro Max" },
  { value: "iphone 12", label: "iPhone 12" },
  { value: "iphone 12 Pro", label: "iPhone 12 Pro" },
  { value: "iphone 12 Pro Max", label: "iPhone 12 Pro Max" },
  { value: "iphone 13", label: "iPhone 13" },
  { value: "iphone 13 Pro", label: "iPhone 13 Pro" },
  { value: "iphone 13 Pro Max", label: "iPhone 13 Pro Max" },
  { value: "iphone 14", label: "iPhone 14" },
  { value: "iphone 14 Pro", label: "iPhone 14 Pro" },
  { value: "iphone 14 Pro Max", label: "iPhone 14 Pro Max" },
  { value: "iphone 15", label: "iPhone 15" },
  { value: "iphone 15 Pro Max", label: "iPhone 15 Pro" },
  { value: "iphone 15 Pro Max", label: "iPhone 15 Pro Max" },
];

export const ADMIN_MENU_LIST_ITEM = [
  { value: "trang-chủ", label: "Trang chủ" },
  { value: "quản-lí-sản-phẩm", label: "Quản lí sản phẩm" },
  { value: "thông-tin-người-dùng", label: "Thông tin người dùng" },
  { value: "tạo-sản-phẩm", label: "Tạo sản phẩm mới" },
];

export const PRODUCTS_CAPACITY = [
  { value: "32GB", label: "32GB" },
  { value: "64GB", label: "64GB" },
  { value: "128GB", label: "128GB" },
  { value: "256GB", label: "256GB" },
  { value: "512GB", label: "512GB" },
  { value: "1TB", label: "1TB" },
];

export const PRODUCTS_CATEGORY_CREATE = [
  { value: "ip7", label: "iPhone 7" },
  { value: "ip8", label: "iPhone 8" },
  { value: "ipX", label: "iPhone X" },
  { value: "ip11", label: "iPhone 11" },
  { value: "ip12", label: "iPhone 12" },
  { value: "ip13", label: "iPhone 13" },
  { value: "ip14", label: "iPhone 14" },
  { value: "ip15", label: "iPhone 15" },
  { value: "ip16", label: "iPhone 16" },
];

export const HERO_IMAGES = [
  {
    value:
      "https://res.cloudinary.com/dvi7vf6ne/image/upload/v1703607838/iphone-14-pro-collection-19_u4zf2s.jpg",
    name: "iPhone 14 pro 99% tao xanh store 128GB",
  },
  {
    value:
      "https://res.cloudinary.com/dvi7vf6ne/image/upload/v1703606937/iphone-15-ultra-concept_u0bolz.jpg",
    name: "iPhone 14 pro max 99% taoxanh store 128GB",
  },
  {
    value:
      "https://res.cloudinary.com/dvi7vf6ne/image/upload/v1723175653/iphone-15pro_uutebw.webp",
    name: "iPhone 14 99% tao xanh store 128GB",
  },
  {
    value:
      "https://res.cloudinary.com/dvi7vf6ne/image/upload/v1703607838/apple-iphone-12-all-collection-111_qd6e0j.jpg",
    name: "iPhone 13 pro max 99% tao xanh store 128GB",
  },
];

export const ELEMENT_ADVERTISE_IMG = [
  {
    value: "/image/khachtaoxanhstore_1.jpeg",
    name: "khách hàng táo xanh dĩ an store",
  },
  {
    value: "/image/khachtaoxanhstore_2.jpeg",
    name: "khách hàng táo xanh dĩ an store",
  },
  {
    value: "/image/khachtaoxanhstore_3.jpeg",
    name: "khách hàng táo xanh dĩ an store",
  },
];

export const SOCIAL_ICON = [
  {
    icon: "/image/phone-call.png",
    label: "Gọi điện",
    url: "tel:+84986543210",
  },
  {
    icon: "/image/social-media.png",
    label: "Facebook",
    url: "https://www.facebook.com/TaoXanhStores",
  },
  {
    icon: "/image/messenger.png",
    label: "Messenger",
    url: "https://m.me/taoxanhstores",
  },
];

export const ADS_IMAGE =
  "https://res.cloudinary.com/dvi7vf6ne/image/upload/v1723175701/taoxanh-image_1_xlzojb.png";
