import { Box, Link, Typography } from "@mui/material";
import React from "react";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

const styles = {
  wrapInfo: {
    width: { sm: 550 },
    p: 1,
  },
  typo: {
    fontSize: { sm: "1.5rem", xs: 12 },
    fontWeight: 500,
    textAlign: "center",
  },

  boxWrapIcon: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    mb: 3,
    justifyContent: "center",
    alignItems: "center",
  },

  link: {
    textDecoration: "none",
    color: "primary.contrastText",
  },

  boxWrapTypo: { boxShadow: 2, borderRadius: 2, p: 1, mb: 3 },

  typoPhone: {
    display: "flex",
    alignItems: "center",
    mt: 1,
  },
};

function DiachiPage() {
  return (
    <Box sx={{ textAlign: "justify", mt: 5, p: 4 }}>
      <Typography mb={2} textAlign="center" fontSize={36} fontWeight={600}>
        Hệ thống chi nhánh cửa hàng
      </Typography>

      <Box sx={styles.boxWrapTypo}>
        <Typography sx={styles.typo}>Chi Nhánh 1:</Typography>
        <Box sx={styles.boxWrapIcon}>
          <Typography>
            - 31 Nguyễn An Ninh, khu phố Nhị Đồng 2, Dĩ An, Bình Dương
          </Typography>
          <Typography sx={styles.typoPhone}>
            <PhoneInTalkIcon sx={styles.icon} fontSize="small" />
            <Link href="tel: 0343.159.159" sx={styles.link}>
              :0343.159.159
            </Link>
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.boxWrapTypo}>
        <Typography sx={styles.typo}>Chi Nhánh 2:</Typography>
        <Box sx={styles.boxWrapIcon}>
          <Typography>
            - 284 Nguyễn Tri Phương, KP4, An Bình, Dĩ An, Bình Dương
          </Typography>
          <Typography sx={styles.typoPhone}>
            <PhoneInTalkIcon fontSize="small" sx={styles.icon} />
            <Link href="tel: 0973.159.579" sx={styles.link}>
              :0973.159.579
            </Link>
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.boxWrapTypo}>
        <Typography sx={styles.typo}>Chi Nhánh 3:</Typography>
        <Box sx={styles.boxWrapIcon}>
          <Typography>
            - 1261 Tỉnh lộ 43, Bình Chiểu, Thành Phố Thủ Đức, TP.HCM
          </Typography>
          <Typography sx={styles.typoPhone}>
            <PhoneInTalkIcon sx={styles.icon} fontSize="small" />

            <Link sx={styles.link} href="tel: 0973.711.789">
              :0973.711.789
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DiachiPage;
