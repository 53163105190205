import { configureStore } from "@reduxjs/toolkit";
import productReducer from "../features/product/productSlice.jsx";
import userReducer from "../features/user/userSlice.jsx";

const rootReducer = {
  products: productReducer,
  users: userReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
