import { useReducer, createContext, useEffect } from "react";
import apiService from "../app/apiService";
import { isValidToken } from "../utils/jwt";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const INITIALIZE = "AUTH.INITIALIZE";
const lOGIN_SUCCESS = "AUTH.LOGIN_SUCCESS";
const LOGOUT = "AUTH.LOGOUT";
const CHANGE_PASSWORD = "AUTH.CHANGE_PASSWORD_SUCCESS";

const AuthContext = createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case lOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };

    case INITIALIZE:
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isInitialized: true,
        isAuthenticated,
        user,
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };

    default:
      return state;
  }
};

const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem("accessToken", accessToken);
    apiService.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    window.localStorage.removeItem("accessToken");
    delete apiService.defaults.headers.common.Authorization;
  }
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await apiService.get("/admin/me");

          const user = response.data.data;

          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user },
          });
        } else {
          setSession(null);

          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (error) {
        setSession(null);

        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };
    initialize();
  }, []);

  const login = async ({ email, password }, callback) => {
    const response = await apiService.post("/auth/login", { email, password });

    const { admin, accessToken } = response.data.data;
    dispatch({
      type: lOGIN_SUCCESS,
      payload: admin,
    });
    setSession(accessToken);

    callback();
  };

  const changePassword = async (
    { password, newPassword, confirmNewPassword },
    callback
  ) => {
    const response = await apiService.post("/auth/change-password", {
      password,
      newPassword,
      confirmNewPassword,
    });

    const { admin, accessToken } = response.data.data;

    dispatch({
      type: CHANGE_PASSWORD,
      payload: admin,
    });
    setSession(accessToken);

    callback();
  };

  const logout = (callback) => {
    setSession(null);

    dispatch({
      type: LOGOUT,
    });

    callback();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
