import { Box, Typography } from "@mui/material";
import React from "react";

function BaoHanhPage() {
  return (
    <Box sx={{ textAlign: "justify", mt: 5, p: 4 }}>
      <Typography mb={2} textAlign="center" fontSize={36} fontWeight={600}>
        Chính sách bảo hành
      </Typography>
      <Typography fontSize={20} fontWeight={500}>
        Bảo hành lên tới 12 tháng (365 ngày)
      </Typography>
      <Typography>
        - 1 đổi 1 trong vòng 30 ngày đầu (lỗi do nhà sản xuất) đối với cùng dòng
        máy.
      </Typography>
      <Typography>
        - Thu đổi lên đời trừ 15% - 30% theo giá thị trường.
      </Typography>
    </Box>
  );
}

export default BaoHanhPage;
