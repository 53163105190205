import { Box, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";

import MenuCategory from "./MenuCategory";

const styles = {
  danhmuc: {
    alignItems: "center",
    display: "flex",
    border: "0.5px dotted #383838",
  },
  category: {
    gap: 1,
    p: 1,
    height: { sm: 500, xs: "100%" },

    width: { sm: 250, xs: "100%" },
    display: {
      xs: "block",
      sm: "none",
      md: "none",
      lg: "none",
    },
    mb: { sm: 0, xs: 3 },
  },

  typo: {
    fontSize: 18,
    fontWeight: 500,
  },

  boxWrap: {
    display: "flex",
    justifyContent: "flex-start",
  },
};

function MobileCategory() {
  return (
    <Box sx={styles.boxWrap}>
      <Box name="MobileCategory" sx={styles.category}>
        <Box name="danh-muc" sx={styles.danhmuc}>
          <MenuIcon fontSize="medium" sx={{ mr: 1 }} />
          <Typography sx={styles.typo}>Tất Cả Danh Mục</Typography>
        </Box>

        <MenuCategory />
      </Box>
    </Box>
  );
}

export default MobileCategory;
