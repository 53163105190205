import * as React from "react";
import { Stack, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";

import MainHeaderAdmin from "./MainHeaderAdmin";

export default function AdminLayout() {
  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <MainHeaderAdmin />
      <Outlet />
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: { background: "#e8f5e9" },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
    </Stack>
  );
}
