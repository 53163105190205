import React, { useState } from "react";
import { SelectField } from "../../components/form";
import { PRODUCTS_CAPACITY } from "../../app/config";

function EditCapacityForm({ capacity, methods }) {
  const [selectedOptions, setSelectedOptions] = useState(capacity || []);

  const handleOptionsChange = (event) => {
    setSelectedOptions(event.target.value);
    methods.setSelectedOptions("capacity", event.target.value);
  };

  return (
    <>
      <SelectField
        name="capacity"
        value={selectedOptions}
        label="Dung lượng"
        onChange={handleOptionsChange}
        selectOption={PRODUCTS_CAPACITY}
        displayEmpty
        multiple
      />
    </>
  );
}

export default EditCapacityForm;
