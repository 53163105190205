import { Box, Typography } from "@mui/material";
import React from "react";

import footerImg from "../../images/taoxanh-logo.jpeg";

const styles = {
  wrap: {
    width: { md: 300, xs: "100%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "start",
    pl: { md: 0, lg: 0, sm: 1, xs: 1 },
    pr: { md: 0, lg: 0, sm: 1, xs: 1 },
    height: { md: 500, xs: 500 },
  },
  typo: {
    fontSize: { sm: 13, xs: 12 },
    fontWeight: 900,
    color: "rgb(99,110,114)",
    mb: 1,
  },

  boxImg: {
    display: "flex",
    justifyContent: "center",
  },
};

function Address() {
  return (
    <Box name="wrap" sx={styles.wrap}>
      <Box sx={styles.boxImg}>
        <Box
          component="img"
          src={footerImg}
          alt="footerimg"
          width={{ md: 200, xs: "50%", sm: 400 }}
        />
      </Box>

      <Box name="address">
        <Typography variant="subtitle1">Địa chỉ:</Typography>

        <Typography variant="subtitle2" sx={styles.typo}>
          CN1: 31 Nguyễn An Ninh, khu phố Nhị Đồng 2, Dĩ An, Bình Dương
        </Typography>

        <Typography sx={styles.typo} variant="subtitle2">
          CN2: 284 Nguyễn Tri Phương, KP4, An Bình, Dĩ An, Bình Dương
        </Typography>

        <Typography sx={styles.typo} variant="subtitle2">
          CN3: 1261 Tỉnh lộ 43, Bình Chiểu, Thành Phố Thủ Đức, TP.HCM
        </Typography>
      </Box>

      <Box name="hotline" width="100%">
        <Typography variant="subtitle1">Hotline: 0274.999.66.77</Typography>
        <Typography variant="subtitle2" sx={styles.typo}>
          CN1: 0343.159.159
        </Typography>
        <Typography variant="subtitle2" sx={styles.typo}>
          CN2: 0973.159.579
        </Typography>
        <Typography variant="subtitle2" sx={styles.typo}>
          CN3: 0973.711.789
        </Typography>
      </Box>
    </Box>
  );
}

export default Address;
