import axios from "axios";
import { CLOUDINARY_UPLOAD_PRESET, CLOUDINARY_CLOUD_NAME } from "../app/config";

export const uploadAdapter = (loader) => {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const image = await loader.file;
          const formData = new FormData();
          formData.append("file", image);
          formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);

          const response = await axios({
            url: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          const imageUrl = response.data.secure_url;
          resolve({
            default: imageUrl,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {},
  };
};
