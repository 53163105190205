import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormProvider, FTextField } from "../../components/form/index";

const defaultValues = {
  query: "",
};

const styles = {
  boxWrap: {
    color: "rgb(33, 43, 54)",
    marginBottom: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1,
    paddingRight: 1,
  },
};

export default function ProductSearch({ page }) {
  const [queryValue, setQueryValue] = useState();

  const navigate = useNavigate();

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    const { query } = data;

    setQueryValue(query);
  };

  useEffect(() => {
    if (queryValue) {
      navigate(`/search?query=${queryValue}`);
    }
    // eslint-disable-next-line
  }, [queryValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2} sx={styles.boxWrap}>
        <FTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon type="submit" />
              </InputAdornment>
            ),
          }}
          placeholder="Tìm kiếm sản phẩm..."
          size="small"
          variant="outlined"
          name="query"
          label="Tìm kiếm"
          color="textField"
          sx={{ width: 300 }}
        />
        <Button
          sx={{ width: "6rem", marginLeft: 2 }}
          type="submit"
          loading={isSubmitting}
          fullWidth
          variant="outlined"
          color="success"
          size="small"
        >
          Tìm kiếm
        </Button>
      </Box>
    </FormProvider>
  );
}
