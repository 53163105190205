import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

const styles = {
  stack: {
    display: " flex",
    justifyContent: "center",
    alignItems: " center",
    marginTop: 4,
    marginBottom: 2,
  },
};

function PaginationComponent() {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const { totalPages } = useSelector((state) => state.products);
  const currentPage = searchParams.get("page");
  const handleChangePage = (item) => {
    return `?page=${item.page}`;
  };

  return (
    <Stack sx={styles.stack}>
      <Pagination
        page={Number(currentPage) || 1}
        fontSize="small"
        count={totalPages}
        color="success"
        renderItem={(item) => (
          <PaginationItem
            component={RouterLink}
            to={handleChangePage(item)}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}

export default PaginationComponent;
