import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box } from "@mui/material";

import { uploadAdapter } from "../../utils/configCkeditor";

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const editorConfiguration = {
  extraPlugins: [uploadPlugin],
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "alignment",
    "|",
    "imageUpload",
    "|",
    "undo",
    "redo",
  ],
  image: {
    styles: ["alignLeft", "alignCenter", "alignRight", "resizeImage"],
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
    ],
    resizeOptions: [
      {
        name: "resizeImage:40",
        value: "40%",
        label: "40%",
      },
      {
        name: "resizeImage:60",
        value: "60%",
        label: "60%",
      },
    ],
  },
};

function EditDescription({ description, setValue }) {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setValue("description", data);
  };

  return (
    <Box>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        data={description}
        onChange={handleEditorChange}
      />
    </Box>
  );
}

export default EditDescription;
