import React, { useState } from "react";
import "./FooterMobile.css";
import { Box, Link } from "@mui/material";
import { useEffect } from "react";
import { SOCIAL_ICON } from "../../app/config";

const styles = {
  boxCover: {
    height: 90,
    backgroundColor: "#fafafa",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxIcon: {
    width: "70vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function FooterMobile() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("footer-mobile-none");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setScrollDirection("footer-mobile");
      } else if (scrollTop < lastScrollTop) {
        setScrollDirection("footer-mobile-none");
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <div id={scrollDirection}>
      <Box sx={styles.boxCover}>
        <Box sx={styles.boxIcon}>
          {SOCIAL_ICON.map((e) => (
            <Box
              key={e.url}
              sx={{
                width: 70,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link underline="none" href={e.url} target="_blank">
                <img src={e.icon} alt="icon" width="24px" height="24px" />
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}

export default FooterMobile;
