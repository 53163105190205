import { createSlice } from "@reduxjs/toolkit";
import apiService from "../../app/apiService";

const initialState = {
  user: {},
  isSuccess: false,
  error: null,
  totalUser: [],
  isLoading: false,
  totalNumberUser: 1,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createInfoCustomerSuccess(state, action) {
      state.isSuccess = true;
      state.error = null;
      state.user = action.payload;
    },
    handleCloseModalSuccess(state) {
      state.isSuccess = false;
      state.user = {};
      state.error = null;
    },
    getInfoCustomerSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.totalUser = action.payload.userInfo;
    },
    getTotalNumberInfoCustomerSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.totalNumberUser = action.payload;
    },
  },
});

export const createInfoCustomer =
  ({ name, email, phoneNumber, product }) =>
  async (dispatch) => {
    try {
      const data = { name, email, phoneNumber, product };

      const response = await apiService.post(`/info-customer`, data);

      dispatch(slice.actions.createInfoCustomerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };

export const handleCloseModal = () => async (dispatch) => {
  dispatch(slice.actions.handleCloseModalSuccess());
};

export const getInfoCustomer = () => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await apiService.get(`/info-customer`);

    dispatch(slice.actions.getInfoCustomerSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error.message));
  }
};

// export const getTotalNumberInfoCustomer = () => async (dispatch) => {
//   try {
//     dispatch(slice.actions.startLoading());
//     const response = await apiService.get(`/info-customer/totalNumber`);

//     dispatch(
//       slice.actions.getTotalNumberInfoCustomerSuccess(response.data.data)
//     );
//   } catch (error) {
//     dispatch(slice.actions.hasError(error.message));
//   }
// };

export default slice.reducer;
