import { Alert, Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import { getSimilarProduct } from "./productSlice";
import ProductCard from "./ProductCard";

const styles = {
  boxWrap: {
    marginBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
};

function SimilarProductPage({ category, idProduct }) {
  const { productList } = useSelector((state) => state.products);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [isProductListFetched, setIsProductListFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (productList.length) {
      const filteredProducts = productList.filter(
        (product) => product._id !== idProduct
      );
      setSimilarProducts(filteredProducts);
      setIsProductListFetched(true);
    }
  }, [productList, idProduct]);

  useEffect(() => {
    if (category) {
      dispatch(getSimilarProduct({ category }));
    }
  }, [category, dispatch]);

  return (
    <Box sx={styles.boxWrap}>
      <Divider sx={{ marginBottom: 2 }} />

      <Swiper
        style={{ padding: "10px" }}
        spaceBetween={10}
        slidesPerView={1.5}
        slidesPerGroup={1}
        loop={true}
        modules={[Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        {similarProducts.length
          ? similarProducts.map((product) => (
              <SwiperSlide key={product._id}>
                <ProductCard productDetail={product} />
              </SwiperSlide>
            ))
          : isProductListFetched && (
              <Alert
                sx={{ width: 270 }}
                variant="outlined"
                color="success"
                severity="info"
              >
                Không có sản phẩm tương tự
              </Alert>
            )}
      </Swiper>
    </Box>
  );
}

export default SimilarProductPage;
