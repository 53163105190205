import { Chip, Stack } from "@mui/material";
import React from "react";

function ProductCapacity({ capacity }) {
  return (
    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
      {capacity?.map((e, index) => (
        <Chip
          key={index}
          label={e}
          size="small"
          color="success"
          variant="outlined"
        />
      ))}
    </Stack>
  );
}

export default ProductCapacity;
