import { Breadcrumbs, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  typo_1: {
    textDecoration: "none",
    color: "primary.dark",
    "&: hover": {
      textDecoration: "underline",
    },
  },
};

function BreakCrumbComponent({ iphone, valueQuery, name, mt, mb }) {
  return (
    <Stack pl={3} mb={mb} mt={mt}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography
          component={RouterLink}
          to="/"
          sx={styles.typo_1}
          underline="none"
          fontSize={15}
          fontWeight={400}
        >
          Trang chủ
        </Typography>

        {iphone && (
          <Typography color="secondary.title" fontSize={15} fontWeight={400}>
            {iphone}
          </Typography>
        )}
        {valueQuery && (
          <Typography fontSize={15} fontWeight={400}>
            Tìm kiếm
          </Typography>
        )}
        {valueQuery && (
          <Typography fontSize={15} color="secondary.title" fontWeight={400}>
            {valueQuery}
          </Typography>
        )}

        {name && (
          <Typography fontSize={15} color="secondary.title" fontWeight={400}>
            {name}
          </Typography>
        )}
      </Breadcrumbs>
    </Stack>
  );
}

export default BreakCrumbComponent;
