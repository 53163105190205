import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FTextField, FormProvider, SelectField } from "../../components/form";
import { Alert, Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import EditCapacityForm from "../../features/admin/EditCapacityForm";
import EditImageForm from "../../features/admin/EditImageForm";
import { createProduct } from "../../features/product/productSlice";
import EditDescription from "../../features/admin/EditDescription";
import { PRODUCTS_CATEGORY_CREATE } from "../../app/config";

const styles = {
  boxWrap: {
    mt: 5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    pb: 3,
  },
};

const createProductSchema = yup.object().shape({
  name: yup.string().required("Tên sản phẩm bắt buộc"),
  price: yup.string().required("Giá tiền bắt buộc"),
  capacity: yup.array().required("Dung lượng bắt buộc"),
  category: yup.string().required("Phân loại bắt buộc"),
  description: yup.string().required("Miêu tả sản phẩm bắt buộc"),
  image: yup.array().required("Miêu tả sản phẩm bắt buộc"),
});

const defaultValues = {
  name: "",
  price: "",
  capacity: [],
  category: "",
  description: "",
  image: [],
};

function CreateProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, isSuccess } = useSelector(
    (state) => state.products
  );

  const methods = useForm({
    resolver: yupResolver(createProductSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    const { name, price, capacity, image, description, category } = data;

    dispatch(
      createProduct({
        name,
        price,
        capacity,
        image,
        description,
        category,
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin/quản-lí-sản-phẩm");
      // Navigate or perform other actions here if needed
    } else if (error) {
      return;
    }
  }, [isSuccess, error, navigate]);

  return (
    <Box sx={styles.boxWrap}>
      <Stack
        direction="column"
        spacing={3}
        width={{ xs: "100%", sm: "100%", md: 600, lg: 800 }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!error && <Alert severity="error">{error}</Alert>}

            <FTextField name="name" label="Tên sản phẩm" />
            <FTextField name="price" label="Giá" />

            <EditCapacityForm capacity={""} methods={methods} />
            <SelectField
              name="category"
              label="Phân loại điện thoại"
              selectOption={PRODUCTS_CATEGORY_CREATE}
            />
            <EditImageForm image={[]} setValue={setValue} />

            <EditDescription description={""} setValue={setValue} />

            <LoadingButton
              type="submit"
              loading={isSubmitting || isLoading}
              fullWidth
              variant="outlined"
              color="success"
            >
              Tạo sản phẩm
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
}

export default CreateProductPage;
