import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { keyframes } from "@emotion/react";
import SkeletonComponent from "../../components/SkeletonComponent";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const styles = {
  boxCard: {
    width: 180,
    height: 180,
    flexWrap: "wrap",
  },

  boxTypo: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  cardMedia: {
    borderRadius: 1.2,
    boxShadow: 2,
    transition: "transform 0.3s, filter 0.3s",
    "&:hover": {
      transform: "scale(0.99)",
      filter: "brightness(0.9)",
      boxShadow: 10,
    },
  },

  card: {
    width: 180,
    boxShadow: 2,
    animation: `${fadeIn} 0.8s ease-out`,
  },
};

function ProductCard({ productDetail }) {
  const navigate = useNavigate();
  const { isLoading, isLoadingSimilar } = useSelector(
    (state) => state.products
  );
  return (
    <>
      {isLoading || isLoadingSimilar ? (
        <SkeletonComponent width={180} />
      ) : (
        <>
          <Card
            sx={styles.card}
            onClick={() => navigate(`/detail/${productDetail?._id}`)}
          >
            <CardActionArea>
              <Box sx={styles.boxCard}>
                <CardMedia
                  sx={styles.cardMedia}
                  component="img"
                  width="100%"
                  height="100%"
                  image={productDetail?.image[1]}
                  alt={productDetail?.name}
                  loading="lazy"
                />
              </Box>

              <CardContent
                sx={{
                  padding: 0.5,
                  height: 55,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={450}
                  color="secondary.title"
                  noWrap
                >
                  {productDetail?.name}
                </Typography>

                <Box sx={styles.boxTypo}>
                  <Typography
                    variant="subtitle4"
                    color="icon.main"
                    fontSize={15}
                  >
                    chỉ từ {productDetail?.price} ₫
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </>
      )}
    </>
  );
}

export default ProductCard;
