import React from "react";
import { Route, Routes } from "react-router-dom";

import BlankLayout from "../layouts/BlankLayout";
import AdminLayout from "../layouts/AdminLayout";
import MainLayout from "../layouts/MainLayout";
import HomePage from "../pages/HomePage";
import DetailPage from "../pages/DetailPage";
import LogInPage from "../pages/admin/LoginPage";
import DashBoard from "../pages/admin/DashBoard";
import useAuth from "../hooks/useAuth";
import NotFoundPage from "../pages/NotFoundPage";
import AuthRequire from "./AuthRequire";
import ProductManagePage from "../pages/admin/ProductManagePage";
import EditProductPage from "../pages/admin/EditProductPage";
import CustomerInformationPage from "../pages/admin/CustomerInformationPage";
import CreateProductPage from "../pages/admin/CreateProductPage";
import BaoMatPage from "../pages/BaoMatPage";
import BaoHanhPage from "../pages/BaoHanhPage";
import DiachiPage from "../pages/DiachiPage";
// import CreateProductPage from "../pages/admin/CreateProductPage";

function Router() {
  const { user } = useAuth();

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="category/:iphone" element={<HomePage />} />
        <Route path="search" element={<HomePage />} />
        <Route path="detail/:productId" element={<DetailPage />} />
        <Route path="/chinh-sach-bao-mat" element={<BaoMatPage />} />
        <Route path="/chinh-sach-bao-hanh" element={<BaoHanhPage />} />
        <Route path="/he-thong-cua-hang" element={<DiachiPage />} />
      </Route>

      {/* admin page */}
      <Route element={<BlankLayout />}>
        <Route path="/admin/login" element={<LogInPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      {user?.role === "admin" && (
        <>
          <Route
            element={
              <AuthRequire>
                <AdminLayout />
              </AuthRequire>
            }
          >
            <Route path="/admin" element={<DashBoard />} />
            <Route
              path="/admin/quản-lí-sản-phẩm"
              element={<ProductManagePage />}
            />
            <Route
              path="/admin/quản-lí-sản-phẩm/:id"
              element={<EditProductPage />}
            />
            <Route
              path="/admin/thông-tin-người-dùng"
              element={<CustomerInformationPage />}
            />
            <Route path="/admin/tạo-sản-phẩm" element={<CreateProductPage />} />
          </Route>
        </>
      )}

      {/* ** */}
    </Routes>
  );
}

export default Router;
