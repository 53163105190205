import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import { ELEMENT_ADVERTISE_IMG } from "../app/config";

function Advertise() {
  return (
    <Box sx={{ pl: 2, pr: 2, mt: 10 }}>
      <Divider textAlign="left">
        <Typography
          fontSize={18}
          fontWeight={600}
          mb={2}
          sx={{ borderBottom: "1px solid #00e676" }}
        >
          KHÁCH HÀNG TÁO XANH STORE
        </Typography>
      </Divider>

      <Box sx={{ display: { sm: "flex", xs: "block" } }}>
        {ELEMENT_ADVERTISE_IMG.map((el, index) => (
          <Box key={index}>
            <img src={el.value} alt={el.name} width="100%" loading="lazy" />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Advertise;
