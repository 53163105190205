import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";
import logoImg from "../images/taoxanh-logo.jpeg";

function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box
      sx={{
        width: 50,
        height: 50,
        ...sx,
        borderRadius: 1,
        overflow: "hidden",
        boxShadow: 4,
      }}
    >
      <img src={logoImg} alt="logo" width="100%" />
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }
  return <RouterLink to="/">{logo}</RouterLink>;
}

export default Logo;
