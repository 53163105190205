import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import { Box, Stack, Typography } from "@mui/material";
import RejectionFile from "./RejectionFile";

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  height: 200,
  padding: theme.spacing(3, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: "#F4F6F8",
  border: `1px dash alpha("#919EAB", 0.32)`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
}));

function UploadMultiFile({ error = false, file, helperText, sx, ...other }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({ multiple: false, ...other });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && {
            padding: "5% 0",
          }),
        }}
      >
        <input {...getInputProps()} />
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {!file?.preview && (
            <>
              <AddPhotoAlternateRoundedIcon />
              <Typography
                gutterBottom
                variant="body2"
                sx={{ color: "#637381" }}
                textAlign="center"
              >
                Drop or Select Image
              </Typography>
            </>
          )}

          {file?.preview && (
            <Box
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                width: "calc(100%-16px)",
                height: "calc(100%-16px)",
                overflow: "hidden",
                "& img": { objectFit: "cover", width: 1, height: 1 },
              }}
            >
              <img height="100%" alt="file preview" src={file?.preview} />
            </Box>
          )}
        </Stack>
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFile fileRejections={fileRejections} />
      )}
      {helperText && helperText}
    </Box>
  );
}

export default UploadMultiFile;
