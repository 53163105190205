import React from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import "./IconPhone.css";
import { Link } from "@mui/material";
function IconPhoneComponent() {
  return (
    <div className="wrapper">
      {/* <div className="ring"> */}
      <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
        <Link underline="none" href="tel: 02749996677">
          <div className="coccoc-alo-ph-circle-fill">
            <div className="coccoc-alo-ph-img-circle">
              <PhoneEnabledIcon color="info" />
            </div>
          </div>
        </Link>
      </div>
      <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show-mess">
        <Link
          underline="none"
          href="https://m.me/taoxanhstores"
          target="_blank"
        >
          <div className="coccoc-alo-ph-circle-fill-mess">
            <div className="coccoc-alo-ph-img-circle-mess">
              <svg xmlns="/image/facebook-mes.svg" viewBox="0 0 512 512">
                <path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z" />
              </svg>
            </div>
          </div>
        </Link>
      </div>
      {/* </div> */}
    </div>
  );
}

export default IconPhoneComponent;
