import { Box, Typography } from "@mui/material";
import React from "react";

function BaoMatPage() {
  return (
    <Box sx={{ textAlign: "justify", mt: 5, p: 4 }}>
      <Typography mb={2} textAlign="center" fontSize={36} fontWeight={600}>
        Chính sách bảo mật
      </Typography>

      <Typography fontSize={20} fontWeight={500}>
        1/ Mục đích thu thập thông tin cá nhân
      </Typography>
      <Typography mb={1}>
        - Bạn có thể ghé thăm và duyệt Website của chúng tôi bình thường mà
        không tiết lộ Thông tin Cá nhân của mình.
      </Typography>
      <Typography mb={4}>
        - Tuy nhiên, trong trường hợp bạn muốn mua hàng, bạn có thể: (a) họ và
        tên của bạn, (b) số điện thoại, (c) sản phẩm bạn quan tâm, chúng tôi sẽ
        liện hệ với bạn. Hoặc bạn có thể ghé trực tiếp tại địa chỉ các chi
        nhánh. Ngoài ra, bạn có thể inbox trực tiếp tại fanpage facebook cửa
        hàng hoặc liện hệ hotline trực tiếp.
      </Typography>

      <Typography fontSize={20} fontWeight={500}>
        2/ Phạm vi sử dụng thông tin
      </Typography>
      <Typography mb={1}>- Cung cấp thông tin quảng bá, khuyến mãi.</Typography>
      <Typography mb={4}>- Lưu hành nội bộ ở cửa hàng.</Typography>

      <Typography fontSize={20} fontWeight={500}>
        3/ Những người hoặc tổ chức có thể được tiếp cận với thông tin đó
      </Typography>
      <Typography mb={1}>
        - Chúng tôi cam kết không chia sẻ hay tiết lộ dữ liệu thông tin của Quý
        khách cho một bên thứ 3 nào khác. Ngoại trừ các trường hợp sau:
      </Typography>
      <Typography mb={1}>- Các đối tác vận chuyển (nếu có).</Typography>
      <Typography mb={1}>
        - Khi nhận thông tin từ khách hàng cung cấp, chúng tôi đảm bảo an toàn
        và bảo mật toàn bộ thông tin bằng các công cụ và giải pháp bảo mật tốt
        nhất.
      </Typography>
    </Box>
  );
}

export default BaoMatPage;
