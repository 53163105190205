import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useNavigate } from "react-router-dom";
import { ADMIN_MENU_LIST_ITEM } from "../../app/config";

export default function ToggleDrawer({
  handleCloseToggle,
  drawer,
  handleToggle,
}) {
  const navigate = useNavigate();
  const handleClickItem = (value) => {
    if (value !== "trang-chủ") {
      navigate(`/admin/${value}`);
    } else {
      navigate("/admin");
    }
  };
  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {ADMIN_MENU_LIST_ITEM.map((e, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => handleClickItem(e.value)}
              divider={true}
            >
              <ListItemText primary={e.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="left"
          open={drawer}
          onClose={handleCloseToggle}
          onOpen={handleToggle}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
