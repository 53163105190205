import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductDetail } from "../features/product/productSlice";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import DescriptionProduct from "../features/product/DescriptionProduct";
import ProductCapacity from "../components/ProductCapacity";
import ModalPopover from "../features/user/ModalPopover";
import BreakCrumbComponent from "../components/BreakCrumbComponent";
import SkeletonComponent from "../components/SkeletonComponent";

import "./DetailPage.css";
import SimilarProductPage from "../features/product/SimilarProductPage";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const styles = {
  card: {
    display: { xs: "block", md: "flex" },
    justifyContent: "space-evenly",
    marginTop: 5,
    animation: `${fadeIn} 0.8s ease-out`,
  },

  wrapImg: {
    display: "flex",
    justifyContent: "space-evenly",
    minWidth: { sm: 450, xs: "100%" },
    flexWrap: "wrap",
  },

  cardContent: {
    minWidth: 400,
    height: 400,
  },

  wrapCardMedia: {
    minHeight: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  cardImg: {
    borderRadius: 2,
    width: 320,
    height: 320,
    boxShadow: 3,
  },

  wrapImgBelow: {
    width: 60,
    border: "0.5px dotted grey",

    "&:hover": {
      cursor: "pointer",
      border: "1px solid #4caf50",
    },
  },

  boxWrapSkeleton: {
    display: "flex",
    justifyContent: "center",
  },
};

function DetailPage() {
  const [indexImg, setIndexImg] = useState(0);
  const [currentTab, setCurrentTab] = useState("description");

  const params = useParams();
  const dispatch = useDispatch();
  const { productId } = params;
  const { productDetail, isLoading } = useSelector((state) => state.products);

  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (!productId) return;
    dispatch(getProductDetail({ productId }));

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [productId, dispatch]);

  const TABS_ELEMENTS = [
    {
      label: "MÔ TẢ",
      value: "description",
      component: <DescriptionProduct description={productDetail.description} />,
    },
    {
      label: "SẢN PHẨM TƯƠNG TỰ",
      value: "similar_products",
      component: (
        <SimilarProductPage
          category={productDetail.category}
          idProduct={productDetail._id}
        />
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Box sx={styles.boxWrapSkeleton}>
          <SkeletonComponent width={"80%"} mt={5} />
        </Box>
      ) : (
        <>
          <BreakCrumbComponent name={productDetail?.name} mt={2} />

          <Card sx={styles.card}>
            <Box sx={styles.wrapCardMedia}>
              <Card sx={styles.cardImg}>
                {productDetail?.image?.length && (
                  <CardMedia
                    component="img"
                    image={productDetail?.image[indexImg]}
                    width="100%"
                    height="100%"
                    alt={productDetail?.name}
                  />
                )}
              </Card>

              {/* render img below */}
              <Box sx={styles.wrapImg}>
                {productDetail?.image?.map((e, index) => (
                  <Box key={index} sx={styles.wrapImgBelow}>
                    <CardMedia
                      className={index === indexImg ? "selected" : "unselected"}
                      component="img"
                      image={e}
                      alt="image"
                      width="100%"
                      height="100%"
                      onClick={() => setIndexImg(index)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            <CardContent sx={styles.cardContent}>
              <Typography
                fontSize={{ sm: 28, xs: 24 }}
                fontWeight={550}
                color="primary.contrastText"
              >
                {productDetail?.name}
              </Typography>

              <Divider sx={{ margin: 1 }} variant="middle" />

              <Box>
                <Typography fontSize={18} fontWeight={600}>
                  Chỉ từ:
                </Typography>
                <Typography variant="h5" color="icon.main">
                  {productDetail?.price} ₫
                </Typography>

                <Typography variant="subtitle2" color="primary.contrastText">
                  Dung Lượng:
                </Typography>
                <ProductCapacity capacity={productDetail.capacity} />

                {/* modal ask user */}
                <ModalPopover />
              </Box>
            </CardContent>
          </Card>
        </>
      )}

      {/* mô tả sản phẩm */}
      <Tabs
        sx={{
          marginTop: 2,
          marginBottom: 2,
          paddingLeft: 3,
          paddingRight: 3,
        }}
        value={currentTab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={(e, value) => handleChangeTab(value)}
      >
        {TABS_ELEMENTS.map((tab) => (
          <Tab
            disableFocusRipple
            value={tab.value}
            key={tab.value}
            label={tab.label}
          ></Tab>
        ))}
      </Tabs>
      {TABS_ELEMENTS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}

export default DetailPage;
