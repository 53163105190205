import React, { useEffect, useMemo } from "react";
import { Alert, Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "./productSlice";
import { useParams, useSearchParams } from "react-router-dom";

import ProductCard from "./ProductCard";
import BreakCrumbComponent from "../../components/BreakCrumbComponent";
import SkeletonComponent from "../../components/SkeletonComponent";

const styles = {
  box: {
    // minWidth: { md: "70%", lg: "70vw", sm: "70%", xs: "100%" },
    width: { sm: "70vw", xs: "100%" },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
};

function ProductList() {
  const dispatch = useDispatch();
  const params = useParams();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const { iphone } = params;
  const valueQuery = searchParams.get("query");
  const currentPage = searchParams.get("page");

  // Fetch products based on query parameters
  useEffect(() => {
    if (valueQuery) {
      dispatch(getProduct({ valueQuery }));
    } else if (iphone) {
      dispatch(getProduct({ iphone }));
    } else {
      dispatch(getProduct({ currentPage }));
    }
  }, [dispatch, valueQuery, iphone, currentPage]);

  const { productList, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    window.scrollTo({ top: 100, behavior: "smooth" });
  }, [currentPage]);

  // Memoize the mapped product components
  const productComponents = useMemo(() => {
    return productList?.map((product) => (
      <Grid
        sx={styles.gridItem}
        key={product?._id}
        item
        sm={6}
        xs={6}
        md={4}
        lg={4}
      >
        <ProductCard productDetail={product} />
      </Grid>
    ));
  }, [productList]);

  return (
    <Box name="product-list" sx={styles.box}>
      {(iphone || valueQuery) && (
        <BreakCrumbComponent mb={5} iphone={iphone} valueQuery={valueQuery} />
      )}

      {/* render product  */}
      {productList?.length ? (
        <Grid
          container
          rowSpacing={5}
          justifyContent={{ xs: "center", sm: "flex-start" }}
          alignItems="center"
        >
          {productComponents}
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <SkeletonComponent width={"85%"} ml={3} />
          ) : (
            <Alert
              sx={{ width: 270 }}
              variant="outlined"
              color="success"
              severity="info"
            >
              Không sản phẩm nào tìm thấy!
            </Alert>
          )}
        </>
      )}
    </Box>
  );
}

export default ProductList;
