import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";

import { getProductByAdmin } from "../../features/product/productSlice";
import LoadingScreen from "../../components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";

const styles = {
  box: { width: { xs: "100%", md: 750 } },
  initialState: {
    pagination: {
      paginationModel: { page: 0, pageSize: 10 },
    },
  },
  boxCover: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
};

export default function ProductManagePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [notFoundData, setNotFoundData] = useState(false);
  const [data, satData] = useState([]);
  const { productList, isLoading } = useSelector((state) => state.products);
  const { user } = useAuth();

  const handleChangeSearch = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
    }, 500);
  };

  const columns = [
    { field: "name", headerName: "Tên", width: 200 },
    { field: "price", headerName: "Giá", width: 200 },
    {
      field: "category",
      headerName: "Phân loại",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Chỉnh sửa",
      width: 100,
      disableColumnFilter: true,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          size="small"
          onClick={() => navigate(`/admin/quản-lí-sản-phẩm/${params.row.id}`)}
        >
          Sửa
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (user) {
      dispatch(getProductByAdmin());
    }
  }, [dispatch, user]);

  useEffect(() => {
    let rows = [];
    productList?.forEach((e) => {
      rows.push({
        id: e._id,
        name: e.name,
        price: e.price,
        category: e.category,
      });
    });
    let originalRows = rows;

    if (searchValue) {
      rows = rows.filter(
        (e) =>
          e.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          e.category.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    if (rows.length === 0) {
      rows = originalRows;
      setNotFoundData(true);
    } else if (!searchValue) {
      setNotFoundData(false);
    }

    satData(rows);
  }, [productList, searchValue]);

  return (
    <Box sx={styles.boxCover}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box sx={styles.box}>
          <Box marginBottom={2}>
            <TextField
              label="Tìm kiếm"
              placeholder="Tìm kiếm sản phẩm"
              color="success"
              size="small"
              onChange={(e) => handleChangeSearch(e)}
            />
            {notFoundData && (
              <Alert sx={{ marginTop: 1 }} severity="warning">
                Không sản phẩm tìm thấy
              </Alert>
            )}
          </Box>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={styles.initialState}
            pageSizeOptions={[5, 10]}
          />
        </Box>
      )}
    </Box>
  );
}
