import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductDetail,
  updateSingleProduct,
} from "../../features/product/productSlice";
import { useForm } from "react-hook-form";
import { FCheckBox, FTextField, FormProvider } from "../../components/form";
import { Alert, Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import EditCapacityForm from "../../features/admin/EditCapacityForm";
import EditImageForm from "../../features/admin/EditImageForm";
import LoadingScreen from "../../components/LoadingScreen";
import EditDescription from "../../features/admin/EditDescription";

const styles = {
  boxWrap: {
    mt: 5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    pb: 3,
    minHeight: "150vh",
  },
};

function EditProductPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productDetail, isLoading, error, isSuccess } = useSelector(
    (state) => state.products
  );
  const { id } = params;

  const methods = useForm({
    defaultValues: {
      name: "",
      price: "",
      capacity: [],
      image: [],
      isDeleted: false,
      description: "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    const productId = id;
    const { name, price, capacity, image, isDeleted, description } = data;
    dispatch(
      updateSingleProduct({
        name,
        price,
        capacity,
        image,
        isDeleted,
        productId,
        description,
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin/quản-lí-sản-phẩm");
      // Navigate or perform other actions here if needed
    } else if (error) {
      return;
    }
  }, [isSuccess, error, navigate]);

  useEffect(() => {
    const productId = id;
    dispatch(getProductDetail({ productId }));
  }, [id, dispatch]);

  // Update form values when productDetail changes
  useEffect(() => {
    if (productDetail) {
      reset({
        name: productDetail.name || "",
        price: productDetail.price || "",
        capacity: productDetail.capacity || [],
        category: productDetail.category || "",
        description: productDetail.description || "",
        image: productDetail.image || [],
        isDeleted: productDetail.isDeleted || false,
      });
    }
  }, [productDetail, reset]);

  return (
    <Box sx={styles.boxWrap}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Stack
          direction="column"
          spacing={3}
          width={{ xs: "100%", sm: "100%", md: 600, lg: 800 }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {!!error && <Alert severity="error">{error}</Alert>}

              <FTextField name="name" label="Tên sản phẩm" />
              <FTextField name="price" label="Giá" />
              <EditCapacityForm
                capacity={productDetail?.capacity}
                methods={methods}
              />
              <FCheckBox
                name="isDeleted"
                label="Xoá sản phẩm"
                color="success"
              />

              <EditImageForm image={productDetail?.image} setValue={setValue} />

              <EditDescription
                description={productDetail?.description}
                setValue={setValue}
              />

              <LoadingButton
                type="submit"
                loading={isSubmitting}
                fullWidth
                variant="outlined"
                color="success"
              >
                Lưu
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Stack>
      )}
    </Box>
  );
}

export default EditProductPage;
