import { Box } from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ProductList from "../features/product/ProductList";
import ProductSearch from "../features/product/ProductSearch";
import PaginationComponent from "../components/PaginationComponent";

import Category from "../components/category/Category";
import MobileCategory from "../components/category/MobileCategory";
import Advertise from "../components/Advertise";

const styles = {
  main: {
    marginTop: 3,
    marginBottom: 10,
  },

  wrapCateProduct: {
    display: "flex",
    justifyContent: "space-evenly",
  },
};

function HomePage() {
  const params = useParams();
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();

  const { iphone } = params;

  return (
    <Box name="main" sx={styles.main}>
      <Box>
        <ProductSearch />
        <MobileCategory />
      </Box>

      <Box sx={styles.wrapCateProduct}>
        <Category />
        <ProductList />
      </Box>

      {iphone ? <></> : <PaginationComponent />}

      {/* advertise khach hang */}
      <Advertise />
    </Box>
  );
}

export default HomePage;
