import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate, Link as RouterLink } from "react-router-dom";

function MenuNavBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOne = () => {
    setAnchorEl(null);
    navigate("/");
  };
  const handleCloseTwo = () => {
    setAnchorEl(null);
    navigate("/he-thong-cua-hang");
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="large"
        edge="start"
        sx={{ mr: 2 }}
      >
        <MenuIcon sx={{ color: "#fff" }} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={RouterLink} to={"/"} onClick={handleCloseOne}>
          Trang Chủ
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={"/he-thong-cua-hang"}
          onClick={handleCloseTwo}
        >
          Cửa Hàng
        </MenuItem>
      </Menu>
    </>
  );
}

export default MenuNavBar;
