import { Box, Typography, Link } from "@mui/material";
import React, { useState } from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";

const styles = {
  boxBorder: {
    width: { md: 200, xs: "100%", sm: "100%" },
    height: 110,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    pl: { md: 1, lg: 0, sm: 1, xs: 1 },
  },

  wrapIcon: {
    width: 150,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
      color: "icon.main",
    },
  },
  typo: {
    fontSize: { sm: 13, xs: 12 },
    fontWeight: 900,
    color: "primary.footerTypo",
  },
  typoEmail: {
    fontWeight: 900,
    color: "rgb(99,110,114)",
  },
};

function IconContact() {
  const [isHovered, setIsHovered] = useState(false);

  const iconColor = isHovered ? "#f00" : "#616161";
  return (
    <Box name="icon" sx={styles.boxBorder}>
      <Box name="wrapIcon" sx={styles.wrapIcon}>
        <Link href="https://www.facebook.com/TaoXanhStores" target="_blank">
          <FacebookOutlinedIcon fontSize="large" sx={styles.icon} />
        </Link>

        <Link href="#">
          <YouTubeIcon fontSize="large" sx={styles.icon} />
        </Link>

        <Link href="https://www.tiktok.com/@taoxanhstores?_t=8iWuHk5Yykj&_r=1">
          <Box
            className="icon-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <svg
              className="icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 448 512"
            >
              <path
                fill={iconColor}
                d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
              />
            </svg>
          </Box>
        </Link>
      </Box>

      <Box>
        <Typography variant="subtitle1">Tư vấn miễn phí 24/7</Typography>
        <Link underline="none" href="tel: 0274.999.66.77">
          <Typography variant="subtitle2" sx={styles.typoEmail}>
            0274.999.66.77
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default IconContact;
