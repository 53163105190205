import { Box } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

import { HERO_IMAGES, ADS_IMAGE } from "../app/config";

const styles = {
  boxCover: {
    minHeight: 400,
    flexWrap: "wrap",
    display: { sm: "flex", xs: "block" },
    boxShadow: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    mb: 2,
  },
};

function HeroImages() {
  return (
    <Box sx={styles.boxCover}>
      <Box
        sx={{
          width: { sm: "50%", xs: "100%" },
        }}
      >
        <img
          src={ADS_IMAGE}
          alt="taoxanh-mobile"
          width="99%"
          height="100%"
          loading="lazy"
        />
      </Box>

      {/* slide swiper */}
      <Box
        sx={{
          width: { sm: "50%", xs: "100%" },
          display: { sm: "flex" },
          alignItems: { sm: "center" },
        }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {HERO_IMAGES.map((el) => (
            <SwiperSlide key={el.name}>
              <Box>
                <img
                  src={el.value}
                  alt={el.name}
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
}

export default HeroImages;
