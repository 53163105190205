import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function SkeletonComponent({ width, height, ...sx }) {
  return (
    <Stack spacing={1} width={width} height={height} {...sx}>
      <Skeleton animation="wave" variant="rectangular" />
      <Skeleton variant="rounded" animation="wave" />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" />
    </Stack>
  );
}

export default SkeletonComponent;
