import { Box, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";

import MenuCategory from "./MenuCategory";

const styles = {
  danhmuc: {
    alignItems: "center",
    display: "flex",
    border: "1px dashed rgba(145, 158, 171, 0.24)",
    borderRight: "none",
  },
  category: {
    height: 500,
    width: "25vw",
    display: {
      xs: "none",
      sm: "block",
      md: "block",
      lg: "block",
    },
    borderRight: "1px dashed rgba(145, 158, 171, 0.24)",
  },

  typo: {
    fontSize: 18,
    fontWeight: 500,
  },
};

function Category() {
  return (
    <Box name="category" sx={styles.category}>
      <Box name="danh-muc" sx={styles.danhmuc}>
        <MenuIcon fontSize="medium" sx={{ mr: 1 }} />
        <Typography sx={styles.typo}>Tất Cả Danh Mục</Typography>
      </Box>

      <MenuCategory />
    </Box>
  );
}

export default Category;
