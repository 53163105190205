import { createSlice } from "@reduxjs/toolkit";
import apiService from "../../app/apiService";
import { PRODUCT_CATEGORY, PRODUCT_PER_PAGE } from "../../app/config";
import { toastError, toastSuccess } from "../../utils/toast";

const initialState = {
  isLoadingSimilar: false,
  isSuccess: false,
  isLoading: false,
  error: null,
  productList: [],
  productCategory: [],
  productDetail: {},
  totalPages: 1,
  totalNumOfProduct: null,
  totalNumOfCustomer: null,
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    startLoading(state) {
      state.isSuccess = false;
      state.isLoading = true;
    },

    startLoadingSimilar(state) {
      state.isLoadingSimilar = true;
    },

    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoadingSimilar = false;
    },

    getProductSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingSimilar = false;
      state.error = null;
      state.productList = action.payload.products;
      state.totalPages = parseInt(action.payload.totalPages);
    },

    getProductDetailSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.productDetail = action.payload;
    },

    getTotalNumberOfProducAndInfoCustomertSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.totalNumOfProduct = action.payload.totalNumberProduct;
      state.totalNumOfCustomer = action.payload.totalNumberOfCustomer;
    },
    createProductSuccess(state) {
      state.isSuccess = true;
      state.isLoading = false;
    },
    updateProductSuccess(state) {
      state.isSuccess = true;
      state.isLoading = false;
    },
  },
});

export const getProduct =
  ({ iphone, valueQuery, currentPage, limit, category }) =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/products?limit=${limit || PRODUCT_PER_PAGE}&isDeleted=false`;

      if (iphone) {
        PRODUCT_CATEGORY.forEach((e) => {
          if (e.category === iphone) {
            url += `&category=${e.value}`;
          }
        });
      }

      if (category) {
        url += `&category=${category}`;
      }
      if (valueQuery) {
        url += `&query=${valueQuery}`;
      }
      if (currentPage) {
        url += `&page=${currentPage}`;
      }

      const response = await apiService.get(url);

      dispatch(slice.actions.getProductSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };

export const getProductDetail =
  ({ productId }) =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiService.get(`/products/${productId}`);

      dispatch(slice.actions.getProductDetailSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };

export const getTotalNumOfProductAndInfoCustomer = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await apiService.get(
      `/products/totalNumberOfProductAndInfoCus`
    );

    dispatch(
      slice.actions.getTotalNumberOfProducAndInfoCustomertSuccess(
        response.data.data
      )
    );
  } catch (error) {
    dispatch(slice.actions.hasError(error.message));
  }
};

export const updateSingleProduct =
  ({ name, price, capacity, image, isDeleted, productId, description }) =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = { name, price, capacity, image, isDeleted, description };
      const response = await apiService.put(`/products/${productId}`, data);

      if (response.data.success) {
        toastSuccess("Cập nhật thành công!");
      }
      dispatch(slice.actions.updateProductSuccess());
    } catch (error) {
      toastError("Cập nhật sản phẩm thất bại!");
      dispatch(slice.actions.hasError(error.message));
    }
  };

export const getProductByAdmin = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await apiService.get("/products/by-admin");

    dispatch(slice.actions.getProductSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error.message));
  }
};

export const createProduct =
  ({ name, price, capacity, image, description, category }) =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const data = { name, price, capacity, image, description, category };

      const response = await apiService.post("/products", data);
      if (response.data.success) {
        toastSuccess("Tạo sản phẩm thành công!");
      }
      dispatch(slice.actions.createProductSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      toastError("Tao sản phẩm thất bại");
    }
  };

export const getSimilarProduct =
  ({ category }) =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingSimilar());
    try {
      let url = `/products?category=${category}`;
      const response = await apiService.get(url);

      dispatch(slice.actions.getProductSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
export default slice.reducer;
