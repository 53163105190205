import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function LoadingScreen({ top, left }) {
  return (
    <Box
      sx={{
        position: "absolute",
        left: `${left}%`,
        top: `${top}%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="success" />
    </Box>
  );
}

export default LoadingScreen;
