import React from "react";
import {
  FormProvider,
  FTextField,
  SelectField,
} from "../../components/form/index";
import * as yup from "yup";
import { PRODUCT_IN_FORM } from "../../app/config";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { createInfoCustomer } from "./userSlice";

const loginSchema = yup.object().shape({
  name: yup.string().required("Tên bắt buộc"),
  phoneNumber: yup.string().required("Số điện thoại bắt buộc"),
  product: yup.string().required("Sản phẩm quan tâm bắt buộc"),
});

const defaultValues = {
  name: "",
  phoneNumber: "",
  product: "",
};

const styles = {
  box: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 2,
  },
  stackCover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
};

function InformationForm() {
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.users);

  const methods = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const { name, email, phoneNumber, product } = data;

    dispatch(createInfoCustomer({ name, email, phoneNumber, product }));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    methods.setValue("product", e.target.value);
  };

  return (
    <Box sx={styles.box}>
      <Typography sx={{ marginBottom: 2 }} fontSize={20} fontWeight={500}>
        Xin vui lòng để lại thông tin
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={styles.stackCover}>
          {!!error && <Alert severity="error">{error}</Alert>}
          <FTextField name="name" label="Tên của bạn" />
          <FTextField name="phoneNumber" label="Số điện thoại" />

          <SelectField
            name="product"
            value={value}
            label="Sản phẩm quan tâm"
            onChange={handleChange}
            selectOption={PRODUCT_IN_FORM}
          />

          <LoadingButton
            loading={isSubmitting}
            type="submit"
            size="small"
            variant="outlined"
            color="success"
          >
            Gửi Thông Tin
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}

export default InformationForm;
