import React, { useState } from "react";
import { FormProvider, FTextField } from "../../components/form/index";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


const loginSchema = yup.object().shape({
  password: yup.string().required("Mật khẩu cũ bắt buộc"),
  newPassword: yup.string().required("Mật khẩu mới bắt buộc"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Xác nhận mật khẩu chưa khớp")
    .required("Xác nhận mât khẩu bắt buộc"),
});

const defaultValues = {
  password: "",
  newPassword: "",
  confirmNewPassword: "",
};

const styles = {
  box: {
    width: 350,
    minHeight: 300,
    p: 1,
    borderRadius: 2,
    boxShadow: 5,
    backgroundColor: "info.main",
    textAlign: "center",
  },
  stackCover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
};

const InputPropsFieldPwd = (isPassword, setIsPassword) => {
  return {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setIsPassword(!isPassword)}
          onMouseDown={(e) => e.preventDefault()}
          edge="end"
        >
          {isPassword ? (
            <VisibilityOff color="success" />
          ) : (
            <Visibility color="success" />
          )}
        </IconButton>
      </InputAdornment>
    ),
  };
};

function ModalChangePassword() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const { password, newPassword, confirmNewPassword } = data;

    try {
      await auth.changePassword(
        { password, newPassword, confirmNewPassword },
        () => {
          navigate("/admin/login");
        }
      );
    } catch (error) {
      reset();
      setError("responseError", error);
    }
  };

  return (
    <Box sx={styles.box}>
      <Typography sx={{ marginBottom: 2 }} fontSize={20} fontWeight={500}>
        Thay đổi mật khẩu
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={styles.stackCover}>
          {!!errors?.responseError && (
            <Alert severity="error">{errors?.responseError.message}</Alert>
          )}

          <FTextField
            name="password"
            label="Mật khẩu cũ"
            type={showOldPassword ? "text" : "password"}
            InputProps={InputPropsFieldPwd(showOldPassword, setShowOldPassword)}
          />
          <FTextField
            name="newPassword"
            label="Mật khẩu mới"
            type={showNewPassword ? "text" : "password"}
            InputProps={InputPropsFieldPwd(showNewPassword, setShowNewPassword)}
          />
          <FTextField
            name="confirmNewPassword"
            label="Xác nhận lại mật khẩu mới"
            type={showNewConfirmPassword ? "text" : "password"}
            InputProps={InputPropsFieldPwd(
              showNewConfirmPassword,
              setShowNewConfirmPassword
            )}
          />

          <LoadingButton
            type="submit"
            size="small"
            variant="outlined"
            color="success"
            loading={isSubmitting}
          >
            Xác nhận
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}

export default ModalChangePassword;
