import { Box, Button, Modal } from "@mui/material";
import React, { useEffect } from "react";
import InformationForm from "./InformationForm";
import { useDispatch, useSelector } from "react-redux";
import FormAfterCreate from "./FormAfterCreate";
import { handleCloseModal } from "./userSlice";

const styles = {
  modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  box: {
    backgroundColor: "#f5f5f5",
    width: 400,
    minHeight: 340,
    p: 1,
    borderRadius: 2,
    boxShadow: 5,
  },
};

function ModalPopover() {
  const [open, setOpen] = React.useState(false);
  const { isSuccess, user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {}, [isSuccess]);

  const handleClose = () => {
    setOpen(false);
    dispatch(handleCloseModal());
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ marginTop: 2 }}
        variant="outlined"
        color="icon"
      >
        Mua Hàng
      </Button>
      <Modal sx={styles.modal} open={open} onClose={handleClose}>
        <Box sx={styles.box}>
          {!isSuccess && (
            <>
              <InformationForm />
            </>
          )}
          {isSuccess && <FormAfterCreate user={user} />}
        </Box>
      </Modal>
    </>
  );
}

export default ModalPopover;
