import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalNumOfProductAndInfoCustomer } from "../../features/product/productSlice";


import useAuth from "../../hooks/useAuth";
import SkeletonComponent from "../../components/SkeletonComponent";

const styles = {
  boxWrap: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: 1,
    p: 2,
  },
  paper: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    width: 350,
    height: 120,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    pl: 5,
    pr: 2,
    borderRadius: 2,
    boxShadow: 5,
  },
};

function DashBoard() {
  const { totalNumOfProduct, isLoading, totalNumOfCustomer } = useSelector(
    (state) => state.products
  );

  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    dispatch(getTotalNumOfProductAndInfoCustomer());

    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {isLoading ? (
        <SkeletonComponent
          position="absolute"
          top="20%"
          left="20%"
          width="60%"
          height={80}
        />
      ) : (
        <>
          <Box sx={{ p: 3 }}>
            <Typography fontSize={20} fontWeight={500}>
              Hi, {user?.email}
            </Typography>
          </Box>

          <Box sx={styles.boxWrap}>
            <Paper sx={styles.paper}>
              <Typography fontSize={14} fontWeight={500}>
                Tổng sản phẩm
              </Typography>
              <Typography fontSize={24} fontWeight={500}>
                {totalNumOfProduct}
              </Typography>
            </Paper>

            <Paper sx={styles.paper}>
              <Typography fontSize={14} fontWeight={500}>
                Tổng người dùng quan tâm sản phẩm
              </Typography>
              <Typography fontSize={24} fontWeight={500}>
                {totalNumOfCustomer}
              </Typography>
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DashBoard;
