import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link as RouteLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ToggleDrawer from "../features/admin/ToggleDrawer";
import ModalChangePassword from "../features/admin/ModalChangePassword";

const styles = {
  menu: {
    vertical: "top",
    horizontal: "right",
  },

  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxCoverNav: {
    width: "45vw",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
};

const NAV_LINKS = [
  { route: "/admin/quản-lí-sản-phẩm", content: "Quản lí sản phẩm" },
  { route: "/admin/tạo-sản-phẩm", content: "Tạo sản phẩm" },
  { route: "/admin/thông-tin-người-dùng", content: "Thông tin khách hàng" },
];
function MainHeaderAdmin() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleToggle = () => {
    setDrawer(!drawer);
  };

  const handleCloseToggle = () => {
    setDrawer(!drawer);
  };

  const handleCloseModalChangePwd = () => {
    setOpen(false);
  };

  const handleOpenModalChangePwd = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleLogout = () => {
    logout(() => {
      navigate("/admin/login");
    });
    setAnchorEl(null);
  };
  return (
    <Box>
      <AppBar position="static" color="success">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => handleToggle()}
          >
            <MenuIcon />
            <ToggleDrawer
              handleCloseToggle={handleCloseToggle}
              drawer={drawer}
              handleToggle={handleToggle}
            />
          </IconButton>
          <Typography
            component={RouteLink}
            to={"/admin"}
            fontSize={20}
            fontWeight={550}
            sx={{ flexGrow: 1, textDecoration: "none", color: "info.main" }}
          >
            Táo Xanh
          </Typography>

          <Box sx={styles.boxCoverNav}>
            {NAV_LINKS.map((nav) => (
              <Typography
                key={nav.content}
                component={RouteLink}
                to={`${nav.route}`}
                fontSize={14}
                fontWeight={550}
                sx={{ textDecoration: "none", color: "info.main" }}
              >
                {nav.content}
              </Typography>
            ))}
          </Box>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={styles.menu}
            keepMounted
            transformOrigin={styles.menu}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleOpenModalChangePwd}>Đổi mật khẩu</MenuItem>
            {/* modal change password */}
            <Modal
              sx={styles.modal}
              open={open}
              onClose={handleCloseModalChangePwd}
            >
              <ModalChangePassword />
            </Modal>

            <MenuItem onClick={() => handleLogout()}>Đăng Xuất</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MainHeaderAdmin;
