import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { Box, IconButton } from "@mui/material";

const styles = {
  boxCover: {
    position: "fixed",
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 10,
    bottom: 25,
    backgroundColor: "#e8f5e9",
    "&: hover": {
      backgroundColor: "#c8e6c9",
    },
    borderRadius: 1,
    opacity: "0.75 !important",
    zIndex: 1000,
  },
};
function IconScrollToTop() {
  return (
    <Box sx={styles.boxCover}>
      <IconButton
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <PublishIcon sx={{ color: "#0a4a49" }} fontSize="large" />
      </IconButton>
    </Box>
  );
}

export default IconScrollToTop;
